/** @jsx jsx */
import { jsx } from "theme-ui"
import { Flex } from "@theme-ui/components"

import { renderLineBreak } from "../helpers"

import Column from "../components/column"
import Container from "../components/container"
import CTA from "../components/cta"
import Carousel from "../components/carousel"
import ProjectCard from "../components/project-card"
import { Link } from "gatsby"
import Row from "../components/row"
import Text from "../components/text"

const ServiceAreaGalleryModule = ({
    bladePatternData,
    projectsData,
    customStyle,
}) => {
    const renderProjectsCards = () => {
        return bladePatternData.contentCards.map((state, idx) => (
            <Column
                size={[12, 12, 4]}
                customStyle={{
                    mb: theme => [theme.spacing.vertical.sm, 0],
                }}
                key={idx}
                margin={"0 -0.5% 0.5% -0.5%"}
            >
                {state.ctaDestination ?
                    <Link
                        to={`/${state.ctaDestination.ctaDestination}`}
                        type="internal"
                    >
                        <ProjectCard
                            key={idx}
                            text={[state.title]}
                            background={state.desktopImage.file.url}
                            sx={{ ...customStyle }}
                        />
                    </Link>
                    :
                    <ProjectCard
                        key={idx}
                        text={[state.title]}
                        background={state.desktopImage.file.url}
                        sx={{ ...customStyle }}
                    />
                }

            </Column>
        ))
    }

    return (
        <div
            id={bladePatternData.anchorLink}
            sx={{
                py: theme => [
                    theme.spacing.vertical.md,
                    theme.spacing.vertical.md,
                    theme.spacing.vertical.lg,
                ],
                backgroundColor: theme => theme.colors.backgrounds.primary,
            }}
        >
            <Container>

                <Row customStyle={{ display: ["none", "none", "flex"] }}>
                    {renderProjectsCards()}
                </Row>
            </Container>
        </div>
    )
}

export default ServiceAreaGalleryModule
