/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import kebabCase from "lodash/kebabCase"
import { findBySlug,renderLineBreak } from "../helpers"
import get from "lodash/get"

import CTAsModule from "../components/ctas-module"
import Hero from "../components/hero"
import Page from "../components/page"
import ProjectUnique from "../components/project-unique"
import BenefitsModule from "../components/benefits-module"
import ServiceAreaGalleryModule from "../components/service-area-gallery-module"
import AdvantagesModule from "../components/advantages-module"
import TestimonialsModule from "../components/testimonials-module"
import ProcessModule from "../components/process-module"
import Quote from "../components/design-partner/quote"

import { paramsCheck } from "../functions/functions"
import { object } from "prop-types"
import { useEffect } from "react"

import DesignPartnerCard from "../components/design-partner-card"
import Column from "../components/column"
import Container from "../components/container"
import PageLink from "../components/page-link"
import RichText from "../components/rich-text"
import Row from "../components/row"
import Text from "../components/text"

const Homebuyers = ({ data, location }) => {
  paramsCheck()
  let hashVal = location.hash.split("?")

  useEffect(() => {
    if (location.hash && hashVal[0] && typeof window !== null) {
      let id = hashVal[0].substring(1)
      const elem = document.getElementById(id)
      if (elem) {
        window.scrollTo({ left: 0, top: elem.offsetTop + 30 })
      }
    }
  }, [])

  const renderHeroModule = () => {
    const heroBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "service-area-hero-module",
    })
    const heroHeights = ["auto", 555]
    if (!heroBladePatternData) return

    const ctaList = heroBladePatternData.contentCards.map(basicCard => ({
      text: basicCard.ctaTitle,
      type: "secondary",
      link: basicCard.ctaDestination
        ? basicCard.ctaDestination.ctaDestination
        : "/",
    }))

    return (
      <Hero
        customStyle={{
          minHeight: heroHeights,
          height: heroHeights,
        }}
        background={{
          type: "image",
          source: {
            mobileImage: heroBladePatternData.mobileImage,
            desktopImage: heroBladePatternData.desktopImage,
          },
        }}
        title={heroBladePatternData.title}
        body={get(heroBladePatternData, "description.description")}
        ctaList={ctaList}
        type="secondary"
      />
    )
  }
  const renderBenefitsModule = () => {
    const benefitsModuleBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "service-area-benefits-module",
    })

    if (!benefitsModuleBladePatternData) return

    return <BenefitsModule bladePatternData={benefitsModuleBladePatternData} />
  }
  const renderServicesModule = () => {
    const servicesModuleBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "service-area-services-module",
    })

    if (!servicesModuleBladePatternData) return

    return <ProjectUnique data={servicesModuleBladePatternData} />
  }
  const renderCtaModule = () => {
    const ctaModuleBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "service-area-cta-module",
    })

    if (!ctaModuleBladePatternData) return

    return (
      <CTAsModule
        textStyle={{ color: theme => theme.colors.white }}
        bladePatternData={ctaModuleBladePatternData}
      />
    )
  }

  const renderProjectGalleryModule = () => {
    const projectGalleryModuleBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "service-area-gallery-module",
    })

    if (!projectGalleryModuleBladePatternData) return

    return (
      <ServiceAreaGalleryModule
        bladePatternData={projectGalleryModuleBladePatternData}
        customStyle={{ pb: "61%", height: 0 }}
      />
    )
  }
  const renderDesignMattersBladePattern = () => {
    const designMattersBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "service-area-design-matters-module",
    })

    if (!designMattersBladePatternData) return
    const description = get(
      designMattersBladePatternData,
      "contentCards[0].description"
    )
    return (
      <div
        sx={{
          pb: theme => theme.spacing.vertical.lg,
          width: "100%",
          overflow: "hidden",
        }}
      >
        <Container
          customStyle={{
            backgroundColor: theme => [
              theme.colors.backgrounds.primary,
              theme.colors.white,
            ],
            py: theme => [
              theme.spacing.vertical.md,
              theme.spacing.vertical.md,
              theme.spacing.vertical.lg,
            ],
          }}
        >
          <Row>
            <Column
              size={[12, 12, 4]}
              customStyle={{
                mb: theme => [theme.spacing.vertical.sm, null],
              }}
            >
              <Text type="h2">
                {renderLineBreak(designMattersBladePatternData.title)}
              </Text>
            </Column>
            <Column size={[12, 12, 4]}>
              {description ? (
                <RichText data={description} />
              ) : (
                <Text
                  type="p"
                  customStyle={{
                    mt: theme => [
                      theme.spacing.vertical.sm,
                      theme.spacing.vertical.sm,
                      0,
                    ],
                  }}
                >
                  {designMattersBladePatternData.description.description}
                </Text>
              )}
            </Column>
          </Row>
        </Container>
      </div>
    )
  }
  return (
    <Page data={data.contentfulPage}>
      {renderHeroModule()}
      {renderDesignMattersBladePattern()}
      {renderProjectGalleryModule()}
      {renderServicesModule()}
      {renderCtaModule()}
      
    </Page>
  )
}

export default Homebuyers

export const query = graphql`
  query serviceAreaPageQuery {
    contentfulPage(slug: { eq: "service-area" }) {
      ...PageData
      ...BladePatternData
    }

    allContentfulProject(limit: 3, sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          projectImages {
            id
            title
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          projectLocation {
            projectLocation
          }
          projectTitle {
            projectTitle
          }
          slug
          projectTytpe
        }
      }
    }
  }
`
